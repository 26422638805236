import React from "react"
import { globalHistory } from '@reach/router'
import PropTypes from "prop-types"



import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import SectionTitle from "../components/home/section-title";

const NotFoundPage = ({firstAttempt}) => {
  const path = globalHistory.location.pathname
  const pageContext = {
    lang: 'EN'
  };
  if (path.includes('/news/') && firstAttempt){
    return (
      <Layout lang={pageContext}>
        <SEO title="404: Not found" />
        <h1>SUCCESS!!!!!</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    )
  }

  return (
    <Layout lang={pageContext}>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

NotFoundPage.defaultTypes = {
  firstAttempt: true,
}

SectionTitle.propTypes = {
  firstAttempt: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
}
